import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';

export enum ModalType {
  ResumeLeadQualif = 'resume_lead_qualif',
}

type AblyChannelsAndEvents = typeof initialAppState.ablyChannelsAndEvents;

// ---------------------------------------- Store definition ---------------------------------------
export type AppState = {
  modal: {
    open: boolean,
    type: `${ModalType}`
  },
  ablyChannelsAndEvents: AblyChannelsAndEvents,
  sponsorshipCode: string | null,
  trackingParamsQuery: string | null,
};

type AppAction = {
  setModal: (open: AppState['modal']['open'], type: AppState['modal']['type']) => void,
  setSponsorshipCode: (code: AppState['sponsorshipCode']) => void,
  setTrackingParamsQuery: (params: AppState['trackingParamsQuery']) => void,
};

// ---------------------------------------- Store creation -----------------------------------------

const initialAppState = {
  modal: {
    open: false,
    type: ModalType.ResumeLeadQualif,
  },
  ablyChannelsAndEvents: {
    hubspot: {
      meetings: {
        channel: 'hubspot:meetings',
        events: {
          new_meeting: 'hubspot_meeting_create_or_update',
        },
      },
    },
    getaccept: {
      documents: {
        channel: 'getaccept:documents',
        events: {
          status_update: 'getaccept_document_status_update',
        },
      },
    },
  },
  sponsorshipCode: null,
  trackingParamsQuery: null,
};

/**
 * Get a form config store for a specific flow type and form config type
 *
 * @param flowType
 * @param formConfigType
 * @param defaultFormConfigSection
 */
const useAppStore = create<AppState & AppAction>()((set) => ({
  ...initialAppState,
  setModal: (open, type) => set({
    modal: {
      open,
      type,
    },
  }),
  setSponsorshipCode: (code) => set({ sponsorshipCode: code }),
  setTrackingParamsQuery: (trackingParamsQuery: AppState['trackingParamsQuery']) => set({ trackingParamsQuery }),
}));

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('AppStore', useAppStore);
}

export default useAppStore;
