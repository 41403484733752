'use client';

import { addToast } from '@little-worker/hestia';
import { useSearchParams } from 'next/navigation';
import queryString from 'query-string';
import { ReactNode, useLayoutEffect } from 'react';

import useAppStore from '@stores/app';

import supportedBrowsers from '../../../supportedBrowsers';

export default function Template({ children }: { children: ReactNode }) {
  const { setTrackingParamsQuery } = useAppStore((state) => ({
    setTrackingParamsQuery: state.setTrackingParamsQuery,
  }));

  const params = useSearchParams();

  let infoShown = false;
  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      if (!supportedBrowsers.test(navigator.userAgent) && !infoShown) {
        addToast({
          variant: 'brand',
          title: 'Attention',
          message: 'Nous avons détecté que votre navigateur ou votre système d\'exploitation n\'est pas à jour, '
            + 'vous risquez d\'avoir une expérience dégradée',
          icon: 'Alert02',
        });
        infoShown = true;
      }
    }

    // Store tracking params in app store
    const trackingParams = queryString.parse(params.toString());
    setTrackingParamsQuery(queryString.stringify(trackingParams));
  }, []);

  return children;
}
